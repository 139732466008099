.video-react .video-react-control.video-react-play-control {
  width: 2em;
}

.video-react .video-react-control.video-react-time-control {
  padding: 0;
}

.video-react .video-react-volume-menu-button {
  display: none;
}
