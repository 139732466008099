@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: $font-weight-normal;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.preloader {
  position: fixed;
  width: 100%;
  height: 0;
  z-index: 1;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  background: rgba(91, 91, 91, 0.2);
  opacity: 0;
  display: flex;
  transition: .5s opacity ease;
  overflow: hidden;
}

.preloader.open {
  height: 100%;
  opacity: 1;
  z-index: 10;
}

.preloader img {
  padding-top: 150px;
}

.react-date-picker__calendar--open, .react-date-picker__calendar--closed {
  position: absolute;
}

.react-date-picker__wrapper {
  border: none;
}

.MuiTableRow-root.total-row .MuiTableCell-root {
  padding: 16px;
  font-size: 0.9rem;
}

.MuiTableCell-root {
  padding-left: 8px;
  padding-right: 4px;
}

.limit-line {
  position: relative;;
  width: 100%;
  height: 4px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  background-color: lightgray;
  overflow: hidden;
}

.limit-line__fill {
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
}

.sleep:before {
  content: url('./../assets/img/sleep.png') !important;
  transform: scale(1.2);
  background: #fff;
  z-index: 99;
  position: relative;
  border-radius: 50%;
  right: -10px;
}

.border-none {
  border: none;
}

.dynamic-save-changes {
  display: none;
  position: absolute;
  z-index: 1;
  top: 15px;
  right: -90px;
}

.dynamic-save-changes.active {
  display: inline-block;
}

.campaigns-stats-add-to-group-passer {
  position: fixed;
  bottom: -30%;
  margin: auto;
  text-align: center;
  transition: 1s all;
  cursor: pointer;
  width: 100%;
}

.campaigns-stats-add-to-group-passer.active {
  bottom: 10%;
}

.thead-sticky {
  z-index: 5;
  position: fixed;
  top: 0;
  background: #fff;
}

#campaigns-table.bundles td.text-break {
  word-break: break-all;
}

.sticky-width-200 {
  min-width: 200px !important;
  max-width: 200px !important;
}

.sticky-width-150 {
  min-width: 150px !important;
  max-width: 150px !important;
}

.sticky-width-110 {
  min-width: 110px !important;
  max-width: 110px !important;
}

.sticky-width-100 {
  min-width: 100px !important;
  max-width: 100px !important;
}

.sticky-width-90 {
  min-width: 90px !important;
  max-width: 90px !important;
}

.sticky-width-80 {
  min-width: 80px !important;
  max-width: 80px !important;
}

.sticky-width-75 {
  min-width: 75px !important;
  max-width: 75px !important;
}

.sticky-width-70 {
  min-width: 70px !important;
  max-width: 70px !important;
}

.sticky-width-60 {
  min-width: 60px !important;
  max-width: 60px !important;
}

.sticky-width-50 {
  min-width: 50px !important;
  max-width: 50px !important;
}

.sticky-width-40 {
  min-width: 40px !important;
  max-width: 40px !important;
}

.min-width-110 {
  min-width: 110px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.min-width-90 {
  min-width: 90px !important;
}

.min-width-85 {
  min-width: 85px !important;
}

.min-width-80 {
  min-width: 80px !important;
}

.min-width-75 {
  min-width: 75px !important;
}

.min-width-70 {
  min-width: 70px !important;
}

.min-width-60 {
  min-width: 60px !important;
}

.min-width-55 {
  min-width: 55px !important;
}

.min-width-50 {
  min-width: 50px !important;
}

.padding-0 {
  padding: 0 8px 0 8px !important;
}

.campaign-filter {
  overflow: hidden;
  transition: 1s all;
  height: auto;
  max-height: 0;
}

.campaign-filter--expanded {
  max-height: 1000px !important;
  overflow: visible;
}

.campaign-filter-preview {
  overflow: hidden;
  transition: 1s all;
  height: auto;
  max-height: 0;
}

.campaign-filter-preview--expanded {
  max-height: 1000px !important;
  overflow: visible;
}

.campaigns-select {
  max-height: 38px;
  transition: 1s all;
  overflow: hidden;
}

.campaigns-select--expanded {
  max-height: 10000px;
  overflow: visible;
}

.campaigns-select--expanded--absolute {
  position: absolute !important;
  z-index: 1;
}

.campaigns-select--expanded--absolute--in-modal {
  overflow-y: auto;
  max-height: 300px;
}

.btn-dark {
  border-color: #abb0b3;
  color: #fff;
  background: #abb0b3;
}

.btn-dark:hover {
  border-color: #9a9da0;
  color: #fff;
  background: #9a9da0;
}

.date-picker-foreground {
  z-index: 3
}