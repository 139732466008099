@import "variables";

// libraries
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-infinite-calendar/styles";

// base
@import "base";
@import "overrides";
@import "extends";
@import "animations";
@import "utils/utils";

// components
@import "components/header";
@import "components/sidebar";
@import "components/content";
@import "components/page";
@import "components/widget";
@import "components/search-input";
@import "components/datatable";
@import "components/video-react";

// themes
@import "themes/colors";

// uncomment line during christmas
@import "custom/_snow.min.css";
