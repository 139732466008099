.rdt_Pagination {
  justify-content: flex-start !important;
}

.action-cell {
  max-width: 80px;
}

.limit-cell--with-line {
  max-width: 80px;
}

.status-cell {
  max-width: 80px;
}

.rate-cell {
  max-width: 80px;
}

.red-border {
  border: 3px solid #00c9ff;
}